.card-style {
    border: 1px solid #e6eefb !important;
}

.card-style:hover {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    transform: scale(1.05);
    transition: all 0.3s ease;
    border: 1px solid #0B6000 !important;
}
